@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#app {
  background-color: #ffffff;
  height: 100%;
}

// Override a css reset.
img {
  display: inline;
}

.header {
  height: 90px;
  background: #f5f5f5;
  img.logo {
    height: 60px;
    max-width: 100%;
    width: auto;
  }
}
.footer {
  background: #f5f5f5;
  color: #757575;
  font-size: 12px;
  a {
    text-decoration: underline;
  }
}
